import Vue from 'vue'
import Main from './Message.vue'
import {mdiAlert} from '@mdi/js'

let MessageConstructor = Vue.extend(Main)

let instance
let instances = []
let seed = 1
const Message = function (options, color, icon, typeClass) {
  // console.log("in Message")
  options = options || {}
  if (typeof options === 'string') {
    // console.log("string")
    options = {
      message: options,
      color: color,
      icon: icon,
      typeClass: typeClass
    }
    // console.log("after 01", options)
  }
  let id = 'message_' + seed++
  // console.log("before MessageConstructor", options)
  instance = new MessageConstructor({
    data: options
  })
  instance.id = id
  // console.log("before mount")
  instance.vm = instance.$mount()
  // console.log("after mount")
  document.body.appendChild(instance.vm.$el)
  instance.vm.visible = true
  instance.dom = instance.vm.$el
  instance.dom.style.zIndex = 10000
  instances.push(instance)
  return instance.vm
}

Message.success = function (option) {
  Message(option, "#67c23a", "icon-Success", "message--success")
}
Message.error = function (option) {
  Message(option, "#CC0033", "icon-error", "message--error")
}
Message.warning= function(option){
	//请更改第二个和第三个参数，第三个参数是图标，就像success的‘√’，第二个参数是图标的颜色
	Message(option,"#67c23a","icon-warning","message--alert")
}

Message.close = function (id) {
  for (let i = 0, len = instances.length; i < len; i++) {
    if (id === instances[i].id) {
      instances.splice(i, 1)
      break
    }
  }
}

Message.closeAll = function () {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close()
  }
}

export default Message
