<template>
  <v-card color="black" flat tile>
    <v-toolbar flat tile prominent dense>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text x-large class="text-h3" @click="onReset">
          <div class="toolbar-btns">重置</div>
        </v-btn>
        <v-btn
          text
          x-large
          class="text-h3"
          @click="onSubmit"
          :loading="loading"
        >
          <div class="toolbar-btns">确定</div>
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text @click="onClose" large>
          <v-icon size="70">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <div class="d-flex justify-space-around py-10">
      <v-card v-for="category in list" :key="category.categoryId" width="400">
        <v-toolbar align-end>
          <v-toolbar-title class="text-h4 text-ali">{{ category.label }}</v-toolbar-title>
          <div v-if="category.capacity" class="d-flex align-end ml-10 mt-1 text-h6 text-ali">
            最多{{ category.capacity }}个
          </div>
        </v-toolbar>
        <v-card-text class="overflow-y-auto">
          <v-list height="60vh">
            <draggable :list="category.list" group="detail">
              <template v-for="(item, index) in category.list">
                <div :key="item.autocode">
                  <v-list-item>
                    <v-list-item-title class="text-h6 text-ali">{{ item.label }}</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                </div>
              </template>
            </draggable>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { deepClone } from '@/utils/utils'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icons: {
        mdiClose
      },
      loading: false,
      list: []
    }
  },
  computed: {
    ...mapGetters([
      'detailRules',
      'projectName',
      'projectStatus',
      'projectShare',
      'projectRoles',
      'userName'
    ])
  },
  created() {
    this.parseList()
  },
  watch: {
    showDialog: {
      handler: function (isOpen) {
        if (isOpen) {
          this.parseList()
        }
      },
      immediate: true
    }
  },
  methods: {
    parseList() {
      var that = this
      this.list = []
      let detailRules = deepClone(this.detailRules)
      for (let i in detailRules.categoryList) {
        let category = detailRules.categoryList[i]
        if (category.canAdjust) {
          let obj = {
            categoryId: category.rowid,
            label: category.label,
            capacity: category.capacity,
            list: category.list
          }
          this.list.push(obj)
        }
      }

      let obj = {
        categoryId: '',
        label: '待分配',
        capacity: 0,
        list: detailRules.extraList
      }
      this.list.push(obj)
    },
    onClose() {
      this.$emit('update:showDialog', false)
    },
    async onSubmit() {
      let submitList = []
      for (let i in this.list) {
        let category = this.list[i]
        if (!category.categoryId) continue

        if (category.capacity && category.list.length > category.capacity) {
          this.$message.error(
            category.label +
              '的列表项个数大于' +
              category.capacity +
              '个，无法提交'
          )
          return
        }

        // 填充 category 和  order
        for (let j = 0; j < category.list.length; j++) {
          let obj = {
            ...category.list[j],
            category: category.categoryId,
            order: j*3
          }
          submitList.push(obj)
        }
      }
      this.loading = true
      await this.$store.dispatch('project/addRules', { list: submitList })
      this.loading = false
      this.$emit('update:showDialog', false)
    },
    onReset() {
      this.parseList()
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar-btns {
  padding-bottom: 5px;
  border-bottom: 2px solid black;
}
</style>