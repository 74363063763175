<template>
  <!-- <v-tabs background-color="background darken-3" show-arrows>
    <v-tabs-slider color="primary lighten-3"></v-tabs-slider>

    <v-tab v-for="i in 30" :key="i" :href="'#tab-' + i"> Item {{ i }} </v-tab>
  </v-tabs> -->
  <v-toolbar-items>
    <div class="d-flex justify-space-between align-center">
      <div
        v-for="(item) in navList"
        :key="item.id"
        class="btn-wrapper mx-0 mx-md-2 mx-lg-1"
      >
        <!-- 不能加nudge-bottom 否则hover离开后menu关闭 -->
        <v-menu open-on-hover bottom offset-y v-if="item.children" rounded="0">
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
              class="nav-btn text-md-h6 text-lg-h4 nav-btn-menu"
              :class="{ 'nav-btn-hover': value }"
              active-class="nav-btn-active"
              :to="item.to"
            >
              <div class="d-flex flex-column align-center">
                {{ item.label }}
                <div class="subtitle-1">{{ item.subtitle }}</div>
              </div>
            </v-btn>
          </template>

          <v-list dark class="nav-menu-list">
            <v-list-item
              v-for="(child) in item.children"
              :key="child.id"
              :to="child.to"
            >
              <v-list-item-title class="nav-menu-listItem-title d-flex justify-center text-md-subtitle-2 text-lg-h6">{{ child.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else
          text
          class="nav-btn text-md-h6 text-lg-h4"
          :to="item.to"
          active-class="nav-btn-active"
        >
          {{ item.label }}
        </v-btn>
      </div>
    </div>
  </v-toolbar-items>
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' }
      ]
    }
  },
  watch: {
    $route: {
      handler: async function (route) {
        console.log('NavMenu watch route', route)
        for (let i in this.navList) {
          let nav = this.navList[i]
          nav.subtitle = ''
          if (nav.children) {
            for (let j in nav.children) {
              let child = nav.children[j]
              if (child.to == route.path) {
                nav.subtitle = child.label
              }
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    onMenuClick() {
      console.log('onMenuClick')
    }
  }
}
</script>

<style lang="scss" scoped>
$btn-hover-opacity: 1;

.btn-wrapper {
  height: 100%;
}
.nav-btn {
  height: 100% !important;
  border-radius: 0px !important;
}
.nav-btn::before {
  background-color: transparent !important;
}
.nav-btn:hover,
.nav-btn[aria-expanded='true'],
.nav-btn-hover {
  background-color: var(--v-primary-base);
  color: white !important;
  opacity: 1;
}

.nav-btn-menu {
  cursor: default;
}

.nav-menu-list {
  border-top: $yumi-border-width solid white;
  .v-list-item:hover,
  .v-list-item--active {
    color: white;
  }
  .nav-menu-listItem-title{

  }
}

.theme--light.v-btn {
  color: var(--v-secondary-base);
}

.nav-btn-active {
  color: black !important;
}
</style>