<template>
  <transition name="message-fade">
    <div class="message" :class="typeClass" role="alert" v-show="visible">
      <div class="message__content">
        <!-- <v-icon large :color="color" :small="true">{{ icon }}</v-icon> &nbsp;{{
          message
        }} -->
        <!-- <i class="material-icons">{{icon}}</i> -->
        <!-- <v-icon>{{icon}}</v-icon> -->
        <i class="iconfont myicon" :class="icon"></i>
        <span class="message__text"> {{ message }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
import { mdiAlert } from "@mdi/js";
export default {
  name: "message",

  data() {
    return {
      visible: false,
      duration: 2000,
      message: "",
      timer: null,
      closed: false,
      color: "",
      icon: "",
      typeClass: "",
    };
  },

  watch: {
    closed(val) {
      if (val) {
        this.visible = false;
        this.$el.addEventListener("transitionend", this.destroyElement);
      }
    },
  },

  methods: {
    destroyElement() {
      this.$el.removeEventListener("transitionend", this.destroyElement);
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },

    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close();
          }
        }, this.duration);
      }
    },

    close() {
      this.closed = true;
    },
  },

  mounted() {
    // 开始定时器
    this.startTimer();
  },
};
</script>

<style lang="scss" scoped>
.message {
  min-width: 380px;
  box-sizing: border-box;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  /* border-color: #ebeef5; */
  position: fixed;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  /* background-color: #edf2fc; */
  transition: opacity 0.3s, transform 0.4s;
  overflow: hidden;
  padding: 15px 15px 15px 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-color: var(--v-primary-base);
  color: var(--v-primary-base);
  font-family: "tianwen";
  color: white;
}

.message--success {
  background-color: var(--v-primary-base);
  border-color: #000;
  /* color: #67c23a; */
}

.message--error {
  background-color: var(--v-primary-base);
  border-color: #000;
  /* color: #cc0033; */
}

.message--alert {
  background-color: var(--v-primary-base);
  border-color: #000;
  /* color: #eeae24; */
}

.message p {
  margin: 0;
}

.message__content {
  padding: 0;
  font-size: 18px;
  /* line-height: 1; */
  display: flex;
  align-content: center;
}

.message__text {
  margin-left: 15px;
}

.message-fade-enter,
.message-fade-leave-active {
  opacity: 0;
  transform: translate(-50%, -100%);
}

.myicon {
  font-size: 24px;
}

//phone
@media (max-device-width: 600px) {
  .message {
    min-width: 300px;
  }
}
</style>

