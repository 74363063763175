<template>
  <div class="user-menu d-flex justify-center align-center">
    <!-- 调整对话框 -->
    <v-dialog
      v-model="showDetailDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <AdjustDialog :showDialog.sync="showDetailDialog"></AdjustDialog>
    </v-dialog>
    <!-- 语言对话框 -->
    <v-dialog
      v-model="showLanguageDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("changeLanguage") }}</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="lang" row>
            <v-radio label="中文" value="zh"></v-radio>
            <v-radio label="English" value="en"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-h5"
            color="primary"
            large
            text
            @click="showLanguageDialog = false"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            class="text-h5"
            color="primary"
            large
            text
            @click="onChangeLanguage"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-menu
      bottom
      left
      transition="scale-transition"
      origin="top center"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="black" icon v-bind="attrs" v-on="on">
          <v-avatar tile color="transparent" size="40px" class="white--text">
            <v-img :src="require('@/assets/images/icons/user.svg')"></v-img>
          </v-avatar>
          <!-- <v-icon size="50px">{{ icons.mdiAccountBoxOutline }}</v-icon> -->
        </v-btn>
        <!-- <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          color="primary"
          size="40px"
          v-bind="attrs"
          v-on="on"
          class="white--text"
        >
          {{ userName[0] }}
        </v-avatar>
      </v-badge> -->
      </template>
      <v-card>
        <v-list dark class="user-profile-menu-content">
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="white" class="primary--text text-h5">
                {{ userName[0] }}
                <!-- <v-img :src="require('@/assets/images/avatars/1.png')"></v-img> -->
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{
                userName
              }}</v-list-item-title>
              <v-list-item-subtitle class="white--text">{{
                roleString
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!-- <v-hover v-slot:default="{ hover }">
            <v-list-item @click="onOpenLanguage">
              <v-list-item-icon class="ms-1 me-4">
                <v-icon size="35" :color="hover ? 'white' : ''">
                  {{ icons.mdiTranslate }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="hover ? 'white--text' : ''">{{
                  $t("language")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-hover> -->
          <v-hover
            v-if="isAdmin && !$vuetify.breakpoint.xs"
            v-slot:default="{ hover }"
          >
            <v-list-item @click="onAdjust">
              <v-list-item-icon class="ms-1 me-4">
                <v-icon size="35" :color="hover ? 'white' : ''">
                  {{ icons.mdiOrderBoolDescendingVariant }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="hover ? 'white--text' : ''"
                  >调整位置</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-list-item @click="onLogout">
              <v-list-item-icon class="ms-1 me-4">
                <v-icon size="35" :color="hover ? 'white' : ''">
                  {{ icons.mdiLogoutVariant }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="hover ? 'white--text' : ''">{{
                  $t("logout")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-hover>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  mdiLogoutVariant,
  mdiAccountBoxOutline,
  mdiOrderBoolDescendingVariant,
  mdiTranslate
} from "@mdi/js"
import { mapGetters } from "vuex"
import i18n from "@/i18n"
import AdjustDialog from "./AdjustDialog.vue"
export default {
  components: {
    AdjustDialog
  },
  data() {
    return {
      icons: {
        mdiLogoutVariant,
        mdiAccountBoxOutline,
        mdiOrderBoolDescendingVariant,
        mdiTranslate
      },
      lang: "zh",
      showDetailDialog: false,
      showLanguageDialog: false
    }
  },
  computed: {
    ...mapGetters([
      "projectName",
      "projectStatus",
      "projectShare",
      "projectRoles",
      "userName",
      "userAvatar"
    ]),
    roleString() {
      let roleList = this.projectRoles.map((item) => {
        if (item == "leader") {
          return "领航员"
        } else if (item == "admin") {
          return "项目管理员"
        } else if (item == "member") {
          return "项目成员"
        } else if (item == "customer") {
          return "客户"
        } else {
          return "未知角色"
        }
      })
      return roleList.join(" ")
    },
    isAdmin() {
      if (this.projectRoles.find((e) => e == "admin")) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    onLogout() {
      this.$messageBox({
        title: "退出系统",
        content: "是否确认退出登录状态?"
      })
        .then(async (res) => {
          console.log("logout")
          await this.$store.dispatch("user/logout")
          // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
          this.$router.push(`/login`)
        })
        .catch((err) => {})
    },
    onAdjust() {
      // this.$message.warning(
      //   "该功能尚未实现！" + this.$vuetify.breakpoint.width
      // );
      this.showDetailDialog = true
    },
    onOpenLanguage() {
      this.lang = localStorage.getItem("language") || "zh"
      this.showLanguageDialog = true
    },
    onChangeLanguage() {
      i18n.locale = this.lang
      localStorage.setItem("language", this.lang) //在localStorage中存入设置
      this.showLanguageDialog = false
      location.reload();
    }
  }
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 3.5rem !important;
  }
}
.user-menu {
  width: 90px;
  height: 100%;
  border-left: $yumi-border-width solid $yumi-border-color;
}
</style>
