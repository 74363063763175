import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify/index'
import router from './router'
import VueClipboard from 'vue-clipboard2'
import * as echarts from 'echarts'
//导入配置好的国际化语言包
import i18n from './i18n' // Internationalization

import './utils/flexible.js'

import Message from './components/Message/index.js'
import MessageBox from './components/MessageBox/index.js'
//引入这个是为了解决有时候图标展示不正常，可以先注释了尝试一下
// import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(VueClipboard)

Vue.prototype.$message = Message
Vue.prototype.$messageBox = MessageBox

Vue.prototype.$echarts = echarts
// 加载svg底图
import bingdongSvg from "@/assets/images/yma/bingdong-flow.svg";
const maps = {'bingdong': bingdongSvg}
for (let key in maps) {
  fetch(maps[key]).then(response => response.text()).then(svgText => {
    // console.log('svg text:', svgText)
    echarts.registerMap(key, { svg: svgText })
  })
}


Vue.config.productionTip = false

import '@/permission' // permission control

// 生产环境阻止鼠标右键
Vue.directive('preventright', {
  bind: function (el, binding, vnode) {
    // el.oncontextmenu = function () { return false }
    if (process.env.NODE_ENV !== 'development') {
      el.oncontextmenu = function () { return false }
    }
  }
})

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
