import {
  getInfo,
  addFav,
  editFav,
  deleteFav,
  addToFav,
  removeFromFav,
  addRules,
  addEvent,
  editEvent,
  deleteEvent,
} from "@/api/project";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";

const getDefaultState = () => {
  return {
    roles: [], //可选: member,customer,admin
    name: "",
    status: "",
    platform: "",
    startDate: "",
    endDate: "",
    share: [], //分享列表
    total: 0, //选手总数,
    displayConfig: "", //客户端显示设置
    //首页信息
    dashboard: {
      yizhao: 0,
      huibao: 0,
      eventTypes: [],
      events: [],
    },
    favList: [],
    detailRules: {
      ruleRowid: "",
      categoryList: [],
      extraList: [],
    },
    subjectRules: {
      ruleRowid: "",
      subjectList: [],
    },
    subjectiveList: []
  };
};

const state = getDefaultState();

const mutations = {
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_STATUS: (state, status) => {
    state.status = status;
  },
  SET_PLATFORM: (state, platform) => {
    state.platform = platform;
  },
  SET_START: (state, value) => {
    state.startDate = value;
  },
  SET_END: (state, value) => {
    state.endDate = value;
  },
  SET_SHARE: (state, share) => {
    state.share = share;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_TOTAL: (state, value) => {
    state.total = value;
  },
  SET_DISPLAY: (state, value) => {
    state.displayConfig = value;
  },
  SET_DASHBOARD: (state, obj) => {
    state.dashboard = Object.assign({}, obj);
  },
  SET_EVENTS: (state, obj) => {
    state.dashboard.events = Object.assign([], obj);
  },
  SET_FAVLIST: (state, obj) => {
    state.favList = Object.assign([], obj);
  },
  SET_DETAILRULES: (state, obj) => {
    state.detailRules = Object.assign([], obj);
  },
  SET_SUBJECTRULES: (state, obj) => {
    state.subjectRules = Object.assign([], obj);
  },
  SET_SUBJECTIVELIST: (state, obj) => {
    state.subjectiveList = Object.assign([], obj);
  },
};

const actions = {
  // get project info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }

          const {
            name,
            status,
            platform,
            startDate,
            endDate,
            share,
            roles,
            total,
            displayConfig,
            dashboard,
            favList,
            detailRules,
            subjectRules,
            subjectiveList
          } = data;

          commit("SET_NAME", name);
          commit("SET_STATUS", status);
          commit("SET_PLATFORM", platform);
          commit("SET_START", startDate);
          commit("SET_END", endDate);
          commit("SET_SHARE", share);
          commit("SET_ROLES", roles);
          commit("SET_TOTAL", total);
          commit("SET_DISPLAY", displayConfig);
          commit("SET_DASHBOARD", dashboard);
          commit("SET_FAVLIST", favList);
          commit("SET_DETAILRULES", detailRules);
          commit("SET_SUBJECTRULES", subjectRules);
          commit("SET_SUBJECTIVELIST", subjectiveList)
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addFav({ commit }, param) {
    return new Promise((resolve, reject) => {
      addFav(param)
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { favList } = data;
          commit("SET_FAVLIST", favList);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editFav({ commit }, param) {
    return new Promise((resolve, reject) => {
      editFav(param)
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { favList } = data;
          commit("SET_FAVLIST", favList);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteFav({ commit }, param) {
    return new Promise((resolve, reject) => {
      deleteFav(param)
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { favList } = data;
          commit("SET_FAVLIST", favList);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addToFav({ commit }, param) {
    return new Promise((resolve, reject) => {
      addToFav(param)
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { favList } = data;
          commit("SET_FAVLIST", favList);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  removeFromFav({ commit }, param) {
    return new Promise((resolve, reject) => {
      removeFromFav(param)
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { favList } = data;
          commit("SET_FAVLIST", favList);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addRules({ commit }, param) {
    return new Promise((resolve, reject) => {
      addRules(param)
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { detailRules } = data;
          commit("SET_DETAILRULES", detailRules);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addEvent({ commit }, param) {
    return new Promise((resolve, reject) => {
      addEvent(param)
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { events } = data;
          commit("SET_EVENTS", events);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editEvent({ commit }, param) {
    return new Promise((resolve, reject) => {
      editEvent(param)
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { events } = data;
          commit("SET_EVENTS", events);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteEvent({ commit }, param) {
    return new Promise((resolve, reject) => {
      deleteEvent(param)
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { events } = data;
          commit("SET_EVENTS", events);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
