<template>
  <v-app>
    <v-app-bar app flat color="background" clipped-left height="75">
      <div class="boxed-container-appbar w-full">
        <div class="d-flex align-center h-full">
          <!-- Left Content -->
          <div class="h-full d-flex d-lg-flex align-center ms-0 ms-lg-4">
            <v-img :src="require('@/assets/logo.png')" max-height="100%" max-width="96px" alt="logo" contain eager
              class="app-logo ms-1"></v-img>
          </div>

          <div class="my-divider"></div>

          <div class="my-title mb-2 ml-6">黄浦江码头岸线安全管理平台</div>

          <!-- <NavMenu :navList="items" class="hidden-sm-and-down"></NavMenu> -->
          <v-spacer></v-spacer>

          <!-- <filter-menu></filter-menu>
          <app-bar-user-menu2></app-bar-user-menu2> -->
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container-main">
        <router-view></router-view>
      </div>
      <!-- <div :style="{ height: footHeight + 'px' }">&nbsp;</div>
      <div v-if="$vuetify.breakpoint.xs" :style="{ height: 200 + 'px' }">
        &nbsp;
      </div> -->
    </v-main>

    <!-- <v-footer fixed padless :height="footHeight">
      <div class="w-full h-full d-flex justify-space-between align-center">
        <div class="x-line"></div>
        <div class="text-h6 text-sm-h3 text-md-h3 text-lg-h2 mx-6 black--text">
          {{ projectName }}
        </div>
        <div class="x-line"></div>
      </div>
    </v-footer> -->
  </v-app>
</template>

<script>
import {
  mdiChartBar,
  mdiCardAccountDetailsOutline,
  mdiClose,
  mdiMenu,
} from "@mdi/js";
import { mapGetters } from "vuex";
import VerticalNavMenu from "./components/vertical-nav-menu/VerticalNavMenu.vue";
import VerticalActorMenu from "./components/VerticalActorMenu.vue";
import AppBarUserMenu from "./components/AppBarUserMenu.vue";
import AppBarUserMenu2 from "./components/AppBarUserMenu2.vue";
import NavMenu from "./components/NavMenu.vue";
import FilterMenu from "./components/FilterMenu.vue";
export default {
  components: {
    VerticalNavMenu,
    VerticalActorMenu,
    AppBarUserMenu,
    AppBarUserMenu2,
    NavMenu,
    FilterMenu,
  },
  data() {
    return {
      icons: {
        mdiChartBar,
        mdiCardAccountDetailsOutline,
        mdiClose,
        mdiMenu,
      },
      catId: "",
      actorId: "",
      filter: "all",
      showShare: false, //显示share页面的tab和filter
      tab: "report", //report statistics
      isActorDrawerOpen: null,
      isDrawerOpen: false,
      isFilterOpen: null,
      items: [
        { id: "dashboard", label: "首页", to: "/dashboard" },
        {
          id: "share",
          label: "选手库",
          subtitle: "",
          to: "/app/share",
          children: [
            { id: "001", label: "第一批", to: "" },
            { id: "002", label: "第二批", to: "" },
            { id: "003", label: "第三批", to: "" },
          ],
        },
        {
          id: "profile",
          label: "选手画像",
          subtitle: "",
          to: "/app/profile",
          children: [
            {
              id: "keguan",
              label: "客观信息统计",
              to: "/app/profile/objective",
            },
            {
              id: "zhuguan",
              label: "主观信息统计",
              to: "/app/profile/subjective",
            },
          ],
        },
        {
          id: "rate",
          label: "结果统计",
          subtitle: "",
          to: "/app/rate",
          children: [
            { id: "dafen", label: "选手打分统计", to: "/app/rate/score" },
            { id: "pingjia", label: "评价结果统计", to: "/app/rate/stage" },
          ],
        },
        { id: "fav", label: "选手专辑", to: "/fav" },
        { id: "note", label: "导演笔记", to: "/app/note" },
      ],
    };
  }, // end data
  computed: {
    ...mapGetters([
      "shareFilter",
      "projectName",
      "projectStatus",
      "projectShare",
      "projectRoles",
      "projectTotal",
      "userName",
    ]),
    footHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 60;
        case "sm":
          return 96;
        case "md":
          return 96;
        case "lg":
          return 96;
        case "xl":
          return 96;
      }
    },
  },
  created() {
    console.log("layout created", this.catId, this.actorId);
    console.log("width:", this.$vuetify.breakpoint.width);
    console.log("height:", this.$vuetify.breakpoint.height);
    console.log("breakpoint", this.$vuetify.breakpoint.name);
    console.log("mobile breakpoint", this.$vuetify.breakpoint.mobileBreakpoint);
    // console.log("===window.orientation===", window.orientation)
    // this.refresh();
  },
  mounted() {
    // window.addEventListener("orientationchange", this.handleOrientationChange);
  },
  watch: {
    $route: {
      handler: async function (route) {
        // console.log('watch route', route.params)
        this.catId = route.params.catId || "";
        this.actorId = route.params.actorId || "";
        if (
          this.$vuetify.breakpoint.name == "lg" ||
          this.$vuetify.breakpoint.name == "xl"
        ) {
          this.isActorDrawerOpen = this.actorId ? true : false;
        }
        if (this.catId && !this.actorId) {
          this.showShare = true;
        } else {
          this.showShare = false;
        }
        // this.redirect = route.query && route.query.redirect
        // console.log('redirect:', this.redirect)
        // if (this.redirect) {
        //   this.$router.push({ path: this.redirect })
        // }
      },
      immediate: true,
    },
    projectShare: {
      handler: function (list) {
        console.log(list);
        let shareList = list.map((item) => {
          let obj = {
            id: item.rowid,
            label: item.title,
            to: item.to,
          };
          return obj;
        });
        shareList.unshift({
          id: "shareall",
          label: "全部选手",
          to: "/app/share/all",
        });
        this.items[1].children = shareList;
      },
      immediate: true,
    },
    isDrawerOpen: {
      handler: function (isLeftOpen) {
        console.log("isDrawerOpen", isLeftOpen);
      },
      immediate: true,
    },
  },
  methods: {
    handleOrientationChange() {
      console.log("===window.screen===", window.screen)
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        alert("portrait");
        // portrait mode
      } else if (orientation === "landscape-primary") {
        alert("landscape");
        // landscape mode
      }
    },
    async refresh() {
      await this.$store.dispatch("project/getInfo");
      console.log("===window.screen===", window.screen)
      // console.log(
      //   this.projectName,
      //   this.projectStatus,
      //   this.projectRoles,
      //   this.projectShare
      // )
    },
    async onFilterChange(value) {
      await this.$store.dispatch("app/toggleShareFilter", value);
      // console.log(this.filter, this.shareFilter)
    },
    async onTabChange(value) {
      await this.$store.dispatch("app/toggleTab", value);
    },
    onClickMenu() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    onClickFilter() {
      this.isFilterOpen = !this.isFilterOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;
    // margin-top: $yumi-border-margin;
    // border-bottom: $yumi-border-width solid $yumi-border-color;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container-appbar {
  height: 100%;
  background-image: linear-gradient(90deg, rgb(0, 68, 182), rgb(0, 68, 182), rgb(34, 162, 255)) !important;
  // border-top: $yumi-border-width solid $yumi-border-color;
  // height: calc(100% - #{$yumi-border-margin});
  // border-left: $yumi-border-width solid $yumi-border-color;
  // border-right: $yumi-border-width solid $yumi-border-color;
  .my-divider{
    margin-left: 26px;
    width: 1px;
    height: 40px;
    background-color: #bbbbbb;
  }

  .my-title{
    font-size: 40px;
    color: white;
    font-family: 'alimama';
  }
}

.boxed-container-main {
  height: calc(100vh - #{$yumi-bar-height});
  // margin-left: $yumi-border-margin;
  // margin-right: $yumi-border-margin;
  // background-color: #CBD6D7;
  // border-left: $yumi-border-width solid $yumi-border-color;
  // border-right: $yumi-border-width solid $yumi-border-color;
}

//zindex 3
.v-footer {
  border-bottom: $yumi-border-width solid $yumi-border-color;
}

.left-line,
.right-line {
  z-index: 150;
  position: fixed;
  top: $yumi-border-margin;
  height: calc(100vh - #{$yumi-border-margin});
  width: calc(#{$yumi-border-width} + #{$yumi-border-margin});
}

.left-line {
  left: 0;
  border-right: $yumi-border-width solid $yumi-border-color;
}

.right-line {
  right: 0;
  border-left: $yumi-border-width solid $yumi-border-color;
}

.x-line {
  background-color: $yumi-border-color;
  height: $yumi-border-width;
  flex: 1;
}
</style>
