export default {
    username: '用户名',
    password: '密码',
    save: '保存',
    edit: '编辑',
    update: '更新',
    delete: '删除',
    forever: '永久',
    expired: '过期',
    logout: '退出登录',
    language:'语言',
    changeLanguage: '修改语言',
    close: '关闭',
    save: '保存'
}
