require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#008EFB',
        secondary: '#C7CED9',
        accent: '#0571B6',
        success: '#7DE314',
        info: '#14A0C0',
        warning: '#FBB03B',
        error: '#FF4C51',
        background: "#CBD6D7"
      },
      dark: {
        primary: '#FFFFFF',
        secondary: '#C7CED9',
        accent: '#AFB8C1',
        success: '#01CC9B',
        info: '#14A0C0',
        warning: '#FBB03B',
        error: '#FF4C51',
        background: "#1C3D74"
      },
    },
  },
}
