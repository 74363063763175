import Cookies from 'js-cookie'

const state = {
  shareId: "",
  shareCode: "",
  shareFilter: "all", // all viewed unview
  shareTab: "report",  // report statistics
  mode: "report",  // report rate
  weidu: "single", // single multiple
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  filter: [
    {
      label: "数字标题",
      name: "test1",
      type: "number",  //数字区间，-3 4-5 6-
      suffix: "岁",  //后缀
      all: false,   //是否全选
      cols: 4,      //占据的列数    total=12-2-1
      values: ['-3', '4-7', '8-9']
    },
    {
      label: "文本标题",
      name: "test2",
      type: "text",  //文本
      suffix: "",  //后缀
      all: true,   //是否全选
      cols: 4,
      values: ['性别', '年龄', '身高', '体重', '所在地', '籍贯', '民族', 'B站粉丝数']
    },
    {
      label: "文本标题",
      name: "test3",
      type: "text",  //文本
      suffix: "",  //后缀
      all: true,   //是否全选
      cols: 4,
      values: ['性别', '年龄', '身高', '体重', '所在地', '籍贯', '民族', '职业', '微博粉丝数', '抖音粉丝数', '小红书粉丝数', 'B站粉丝数']
    }
  ],
  //当前filter的result
  filterResult: [
    {
      name: "test1",
      type: "number",
      values: ['-3']
    }
  ],
  device: 'desktop'
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  TOGGLE_SHAREFILTER: (state, filter) => {
    state.shareFilter = filter
  },
  TOGGLE_TAB: (state, tab) => {
    state.shareTab = tab
  },
  SET_FILTER: (state, filter) => {
    state.filter = filter
  },
  SET_FILTER_RESULT: (state, value) => {
    state.filterResult = value
  },
  SET_MODE: (state, value) => {
    state.mode = value
  },
  SET_WEIDU: (state, value) => {
    state.weidu = value
  },
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  toggleShareFilter({ commit }, filter) {
    commit('TOGGLE_SHAREFILTER', filter)
  },
  toggleTab({ commit }, tab) {
    commit('TOGGLE_TAB', tab)
  },
  //每个页面设置自己的filter
  setFilter({ commit }, value) {
    commit('SET_FILTER', value)
    commit('SET_FILTER_RESULT', [])
  },
  //有过滤器组件来设置当前filter的result
  setFilterResult({ commit }, value) {
    commit('SET_FILTER_RESULT', value)
  },
  setMode({ commit }, value) {
    commit('SET_MODE', value)
  },
  setWeidu({ commit }, value) {
    commit('SET_WEIDU', value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
