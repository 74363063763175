const getters = {
  //app
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  shareFilter: state => state.app.shareFilter,
  shareTab: state => state.app.shareTab,
  filter: state => state.app.filter,
  filterResult: state => state.app.filterResult,
  viewMode: state => state.app.mode,
  rateMode: state => state.app.weidu,
  //user
  token: state => state.user.token,
  userAvatar: state => state.user.avatar,
  userName: state => state.user.name,
  //project
  projectName: state => state.project.name,
  projectStatus: state => state.project.status,
  projectStart: state => state.project.startDate,
  projectEnd: state => state.project.endDate,
  projectShare: state => state.project.share,
  projectRoles: state => state.project.roles,
  projectTotal: state => state.project.total,
  displayConfig: state => state.project.displayConfig,
  projectDashboard: state => state.project.dashboard,
  favList: state => state.project.favList,
  detailRules: state => state.project.detailRules,
  subjectRules: state => state.project.subjectRules,
  subjectiveList: state => state.project.subjectiveList,
}
export default getters
