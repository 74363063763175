import Vue from 'vue'
import Vuetify from 'vuetify/lib';
import Main from '@/components/MessageBox/MessageBox.vue'


const MessageBox = function (options) {
  const VueComponent = Vue.extend(Main)
  const vm = new VueComponent()
  vm.$vuetify = new Vuetify().framework
  vm.$mount()

  let init = false;
  let defaultOptions = {

  }
  Object.assign(vm, defaultOptions, options, {
    type: "confirm"
  })
  if (!init) {
    document.body.appendChild(vm.$el)
    init = true;
  }
  return vm.confirm();
};

export default MessageBox;
