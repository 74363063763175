<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    clipped
    temporary
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-3 pe-5 pt-5 pb-2">
      <div class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logos/logo.svg')"
          max-height="40px"
          max-width="40px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title white--text">{{ projectName }}</h2>
        </v-slide-x-transition>
      </div>
    </div>

    <!-- <v-list-item class="px-2 ml-2 mb-2 mt-1">
      <v-list-item-avatar>
        <v-img :src="userAvatar"></v-img>
      </v-list-item-avatar>

      <v-list-item-title>{{ userName }}</v-list-item-title>
    </v-list-item> -->

    <v-divider></v-divider>

    <!-- <v-breadcrumbs :items="items" divider="/"> </v-breadcrumbs> -->

    <!-- Navigation Items -->
    <v-list shaped class="vertical-nav-menu-items pr-2" dark>
      <nav-menu-link
        title="首页"
        to="/dashboard"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>

      <nav-menu-group
        v-if="shareList.length"
        title="选手库"
        :icon="icons.mdiAccountGroupOutline"
        :types="['share']"
      >
        <nav-menu-link
          title="全部选手"
          to="/app/share/all"
          avatarValue="全"
          :actNumber="totalNumber"
        ></nav-menu-link>
        <nav-menu-link
          v-for="item in shareList"
          :key="item.rowid"
          :title="item.title"
          :to="item.to"
          :avatarValue="item.avatar"
          :actNumber="item.actNumber"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        title="选手画像"
        :icon="icons.mdiChartBoxOutline"
        :types="['profile']"
      >
        <nav-menu-link
          title="客观信息统计"
          to="/app/profile/objective"
          avatarValue="客"
        ></nav-menu-link>
        <nav-menu-link
          title="主观信息统计"
          to="/app/profile/subjective"
          avatarValue="主"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        title="结果统计"
        :icon="icons.mdiFormatListBulleted"
        :types="['rate']"
      >
        <nav-menu-link
          title="选手打分统计"
          to="/app/rate/score"
          avatarValue="分"
        ></nav-menu-link>
        <nav-menu-link
          title="选手评价结果统计"
          to="/app/rate/stage"
          avatarValue="评"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-link
        title="选手专辑"
        to="/fav"
        :icon="icons.mdiFolderAccountOutline"
      ></nav-menu-link>

      <nav-menu-link
        title="导演笔记"
        to="/app/note"
        :icon="icons.mdiNoteEditOutline"
      ></nav-menu-link>

      <!-- <nav-menu-section-title title="批次列表"></nav-menu-section-title> -->

      <!-- <v-list-item
        class="vertical-nav-menu-link"
        link
        active-class="bg-gradient-primary white--text"
        @click="onLogout"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto"> {{icons.mdiLogoutVariant}} </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title> 退出 </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiHomeOutline,
  mdiFileOutline,
  mdiAccountGroupOutline,
  mdiChartBoxOutline,
  mdiFormatListBulleted,
  mdiFolderAccountOutline,
  mdiNoteEditOutline,
} from "@mdi/js";
import { mapGetters } from "vuex";
// eslint-disable-next-line object-curly-newline
import NavMenuSectionTitle from "./components/NavMenuSectionTitle.vue";
import NavMenuGroup from "./components/NavMenuGroup.vue";
import NavMenuLink from "./components/NavMenuLink.vue";

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
    shareList: {
      type: Array,
      default: [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiHomeOutline,
        mdiFileOutline,
        mdiFolderAccountOutline,
        mdiAccountGroupOutline,
        mdiChartBoxOutline,
        mdiFormatListBulleted,
        mdiNoteEditOutline,
      },
      catId: this.$route.params.catId,
      actorId: this.$route.params.rowid,
    };
  },
  computed: {
    ...mapGetters([
      "projectName",
      "projectStatus",
      "projectShare",
      "projectRoles",
      "userName",
      "userAvatar",
      "favList",
    ]),
    totalNumber() {
      let total = 0;
      for (let i in this.shareList) {
        total += this.shareList[i].actNumber;
      }
      return total;
    },
  },
  created() {},
  methods: {
    onLogout() {
      this.$messageBox({
        title: "退出系统",
        content: "是否确认退出登录状态?",
      })
        .then(async (res) => {
          console.log("logout");
          await this.$store.dispatch("user/logout");
          this.$router.push(`/login?redirect=${this.$route.fullPath}`);
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, "background");
}

.app-navigation-menu {
  z-index: 200;
  background-color: var(--v-primary-base) !important;
  .vertical-nav-header {
    color: white !important;
  }
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}
</style>
