<template>
  <v-list-item
    class="vertical-nav-menu-link"
    v-bind="$attrs"
    active-class="bg-gradient-primary white--text"
  >
    <!-- <v-list-item-avatar v-if="avatarImg || avatarValue" size="33">
      <v-img v-if="avatarImg" :src="avatarImg"></v-img>
      <v-avatar v-else color="rgba(200, 200, 200, 0.3)">
        <span>{{ avatarValue }}</span>
      </v-avatar>
    </v-list-item-avatar> -->

    <v-list-item-icon v-if="icon !== undefined">
      <v-icon :class="{ 'alternate-icon-small': !icon }" class="mx-auto">
        {{ icon || alternateIcon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-avatar v-else size="33">
      <v-avatar color="rgba(200, 200, 200, 0.3)">
        <span>{{ avatarValue }}</span>
      </v-avatar>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        {{ title }}
      </v-list-item-title>
      <!-- <v-list-item-subtitle>
        <strong class="red--text">{{ hasCommentText }}</strong>
      </v-list-item-subtitle> -->
    </v-list-item-content>
    <v-chip v-if="actNumber != -1" small color="info">{{ actNumber }}</v-chip>
  </v-list-item>
</template>

<script>
import { mdiCheckboxBlankCircleOutline } from '@mdi/js'
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: undefined
    },
    avatarImg: {
      type: String,
      default: ''
    },
    avatarValue: {
      type: [Number, String],
      default: undefined
    },
    actNumber: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      alternateIcon: 'mdiCheckboxBlankCircleOutline'
    }
  },
  computed: {
    hasCommentText() {
      return this.hasComment ? '已评价' : '未评价'
    }
  }
}
</script>

<style lang="scss" scoped>
// @import '~vuetify/src/styles/styles.sass';

.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}
</style>
