import axios from 'axios'
import Message from '../components/Message/index.js'
import MessageBox from '../components/MessageBox/index.js'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = " Bearer " + getToken()
    }
    config.headers['Language'] = localStorage.getItem("language")
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    
    // if the custom code is not 20000, it is judged as an error.
    if (!res.success) {
      let message = ""
      console.log("get error response:", res)
      if (res.code >= 401 && res.code <= 403) {
        let title = "系统错误"
        let content = "请联系管理员"
        message = title + " " + content
        if(res.code == 402){
          title = "登录无效"
          content = "请重新登录"
          message = title + " " + content
        }else if(res.code == 403){
          title = "登录过期"
          content = "请重新登录"
          message = title + " " + content
        }

        console.log("show message box")

        //鉴权错误 重新登录
        MessageBox({
          title: title,
          content: content,
          showCancel: false
        })
          .then((res) => {
            store.dispatch('user/resetToken').then(() => {
              location.reload()
            })
          })
      } else {
        console.log("show message")
        message = res.message + " 请重新登录"
        Message.error(res.message || 'Error' + " 请重新登录")
      }

      // // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      return Promise.reject(new Error(message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('request error:', error) // for debug
    Message.error(error.message.toString() || 'Error')
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
