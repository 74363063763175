export default {
    username: 'username',
    password: 'password',
    save: 'Save',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    forever: 'Forever',
    expired: 'Expired',
    logout: 'logout',
    language:'language',
    changeLanguage: 'Language Select ',
    close: 'close',
    save: 'save'
}