import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import preset from './default-preset/preset'
// import preset from './preset'
import zhHans from 'vuetify/lib/locale/zh-Hans'

Vue.use(Vuetify)

import zh from 'vuetify/src/locale/zh-Hans.ts'

import PreviousIcon from '@/components/svg/previous.vue'
import YMATodayIcon from '@/components/svg/today.vue'


export default new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
    values: {
      previous: { // name of our custom icon
        component: PreviousIcon, // our custom component
      },
      ymaToday: {
        component: YMATodayIcon
      }
    },
  },
  theme: {
    // dark: true,
    options: {
      customProperties: true,
      variations: false,
    },
  },
  lang: {
    locales: { zhHans },
    current: 'zhHans',
  },
})
