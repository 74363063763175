import request from '@/utils/request'

export function getInfo() {
  return request({
    url: '/project/info',
    method: 'get'
  })
}

export function addFav(data) {
  return request({
    url: '/fav/add',
    method: 'post',
    data
  })
}

export function editFav(data) {
  return request({
    url: '/fav/edit',
    method: 'post',
    data
  })
}

export function deleteFav(data) {
  return request({
    url: '/fav/delete',
    method: 'post',
    data
  })
}

export function addToFav(data) {
  return request({
    url: '/fav/addTo',
    method: 'post',
    data
  })
}

export function removeFromFav(data) {
  return request({
    url: '/fav/removeFrom',
    method: 'post',
    data
  })
}

export function addRules(data) {
  return request({
    url: '/rule/add',
    method: 'post',
    data
  })
}

// event
export function addEvent(data) {
  return request({
    url: '/event/add',
    method: 'post',
    data
  })
}

export function editEvent(data) {
  return request({
    url: '/event/edit',
    method: 'post',
    data
  })
}

export function deleteEvent(data) {
  return request({
    url: '/event/delete',
    method: 'post',
    data
  })
}
