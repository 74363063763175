var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-menu d-flex justify-center align-center"},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showDetailDialog),callback:function ($$v) {_vm.showDetailDialog=$$v},expression:"showDetailDialog"}},[_c('AdjustDialog',{attrs:{"showDialog":_vm.showDetailDialog},on:{"update:showDialog":function($event){_vm.showDetailDialog=$event},"update:show-dialog":function($event){_vm.showDetailDialog=$event}}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.showLanguageDialog),callback:function ($$v) {_vm.showLanguageDialog=$$v},expression:"showLanguageDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("changeLanguage")))])]),_c('v-card-text',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}},[_c('v-radio',{attrs:{"label":"中文","value":"zh"}}),_c('v-radio',{attrs:{"label":"English","value":"en"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-h5",attrs:{"color":"primary","large":"","text":""},on:{"click":function($event){_vm.showLanguageDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")]),_c('v-btn',{staticClass:"text-h5",attrs:{"color":"primary","large":"","text":""},on:{"click":_vm.onChangeLanguage}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)],1),_c('v-menu',{attrs:{"bottom":"","left":"","transition":"scale-transition","origin":"top center","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"black","icon":""}},'v-btn',attrs,false),on),[_c('v-avatar',{staticClass:"white--text",attrs:{"tile":"","color":"transparent","size":"40px"}},[_c('v-img',{attrs:{"src":require('@/assets/images/icons/user.svg')}})],1)],1)]}}])},[_c('v-card',[_c('v-list',{staticClass:"user-profile-menu-content",attrs:{"dark":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"primary--text text-h5",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.userName[0])+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.userName))]),_c('v-list-item-subtitle',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.roleString))])],1)],1),_c('v-divider'),(_vm.isAdmin && !_vm.$vuetify.breakpoint.xs)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{on:{"click":_vm.onAdjust}},[_c('v-list-item-icon',{staticClass:"ms-1 me-4"},[_c('v-icon',{attrs:{"size":"35","color":hover ? 'white' : ''}},[_vm._v(" "+_vm._s(_vm.icons.mdiOrderBoolDescendingVariant)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:hover ? 'white--text' : ''},[_vm._v("调整位置")])],1)],1)]}}],null,false,862629632)}):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{on:{"click":_vm.onLogout}},[_c('v-list-item-icon',{staticClass:"ms-1 me-4"},[_c('v-icon',{attrs:{"size":"35","color":hover ? 'white' : ''}},[_vm._v(" "+_vm._s(_vm.icons.mdiLogoutVariant)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:hover ? 'white--text' : ''},[_vm._v(_vm._s(_vm.$t("logout")))])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }