import router from "@/router";
import store from "@/store";
import Message from "./components/Message/index.js";
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";

const whiteList = ["/login", "/test", "/dashboard"]; // no redirect whitelist
const cancelList = ['/app/share', '/app/profile', '/app/rate']

router.beforeEach(async (to, from, next) => {
  console.log("in beforeEach", to.path);

  if (cancelList.indexOf(to.path) != -1) {
    //取消该导航
    return false;
  }

  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();

  console.log("hasToken:", hasToken ? "[yes]" : "[no]", "to:", to.path);

  if (hasToken) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
    } else {
      const hasGetUserInfo = store.getters.userName;
      if (hasGetUserInfo) {
        next();
      } else {
        try {
          // get user info
          await store.dispatch("user/getInfo");

          next();
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch("user/resetToken");
          Message.error(error.toString() || "Has Error");
          next(`/login?redirect=${to.path}`);
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
    }
  }
});

router.afterEach(() => {
  // finish progress bar
});
