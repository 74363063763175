<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    right
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-2">
      <nav-menu-section-title title="艺人列表"></nav-menu-section-title>

      <nav-menu-link
        v-for="item in actorList"
        :key="item.rowid"
        :title="item.name"
        :to="item.to"
        :avatarImg="item.avatarImg"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import NavMenuSectionTitle from './vertical-nav-menu/components/NavMenuSectionTitle.vue'
import NavMenuLink from './vertical-nav-menu/components/NavMenuLink.vue'
export default {
  components: {
    NavMenuSectionTitle,
    NavMenuLink
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null
    },
    actorList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      catId: this.$route.params.catId,
      actorId: this.$route.params.rowid,
      list: [
        {
          title: '测试人员01',
          avatar: 'https://randomuser.me/api/portraits/lego/2.jpg',
          to: '/share/' + this.$route.params.catId + '/actor/001',
          rowid: 'ID01',
          hasComment: false
        },
        {
          title: '测试人员02',
          avatar: 'https://randomuser.me/api/portraits/lego/6.jpg',
          to: '/share/' + this.$route.params.catId + '/actor/002',
          rowid: 'ID02',
          hasComment: true
        }
      ]
    }
  },
  created() {
    console.log('layout created')
    this.refresh()
  },
  methods: {
    refresh() {}
  }
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}
</style>

